/** @jsx jsx */
import { jsx, Label, Checkbox, Input } from 'theme-ui';

import React, { Component } from 'react';
import validator from 'validator';
import jwt_decode from 'jwt-decode';
import bg from '../images/bg.webp';
import config from '../config';
import signUpImage from '../images/signup-image.jpg';
import { Flex, Box, Text, Button, Card } from 'rebass';
import Logo from './logo';

export default class Signed_In extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                name: "",
                email: "",
                token: null
            }
        }
    }

    componentDidMount() {
        if (localStorage.getItem('jwt') !== null) {
            if (!validator.isJWT(localStorage.getItem('jwt'))) {
                this.props.history.push('/sign-in');
            } else {
                /* var user = jwt_decode(localStorage.getItem('jwt'));
                this.setState({
                    user: {
                        name: user.name,
                        email: user.email,
                        token: localStorage.getItem('jwt')
                    }
                }) */
                var token = localStorage.getItem('jwt');
                if (token) {
                    if (validator.isJWT(token)) {
                        var user = jwt_decode(token);
                        const values = { email: user.email }; /* ${process.env.apiUrl} */
                        fetch(`${config.api.url}/sso/signed-in`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(values),
                        }).then((response) => response.json()).then((data) => {
                            if (data.user) {
                                this.setState({
                                    user: {
                                        name: user.name,
                                        email: user.email,
                                        token: localStorage.getItem('jwt')
                                    }
                                })
                                /* if (user.role === "manager") {
                                    this.setState({ isAdmin: true });
                                } */
                            } else {
                                localStorage.clear();
                                this.props.history.push('/sign-in');
                            }
                        }).catch((error) => {
                            console.error('Error:', error);
                        });
                    }
                }
            }
            if (localStorage.getItem('url') !== null) {
                this.setState({ referrer: localStorage.getItem('url') })
            } else {
                this.setState({ referrer: `${config.api.url}/sign-in` });
            }
        } else {
            this.props.history.push('/sign-in');
        }
    }

    signOut = () => {
        const values = { email: this.state.user.email };
        fetch(`${config.api.url}/sso/sign-out`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((response) => response.json()).then((data) => {
            if (data.user) {
                localStorage.clear();
                this.props.history.push('/sign-in');
            } else {
                this.props.alert.error(data.message);
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    manage = () => {
        this.props.history.push('/manage-account');
    }

    reRoute = () => {
        window.location.assign(this.state.referrer + "?jwt=" + this.state.user.token);
    }

    render = () => {
        return (
            <React.Fragment>
                <Flex height={'100vh'} sx={{ flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap', 'nowrap'] }}>
                    <Box minHeight={150} sx={{ background: `url(${bg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', overflow: 'hidden', position: 'static' }} width={[1, 1, 1, 1 / 2, 1 / 1.8, 1 / 1.6]}>
                    </Box>
                    <Box overflowY='auto' width={[1, 1, 1, 1 / 2, 1 / 2.3, 1 / 2.7]}>
                        <Box sx={{ mt: 40, mb: 0, mx: 'auto' }}>
                        <Flex justifyContent='center'>
                        <Logo sx={{ height: '30px', width: '250px'}}/>
                        </Flex>
                    </Box>
                        <Text variant='texts.welcome'></Text>
                        <Box sx={{ mt: 40, mx: 'auto', textAlign: 'center' }} px={[20, 120, 120, 120, 120, 120]}>
                        <h2 className="form-title">You're Signed in.</h2>
                        <br />
                        <p>Name: <b sx={{fontFamily: 'gilroyB'}}>{this.state.user.name}</b></p>
                        <p>Email: <b sx={{fontFamily: 'gilroyB'}}>{this.state.user.email}</b></p> <br />
                        </Box>
                        <br /><br />
                        <Box px={[20, 120, 120, 120, 120, 120]}>
                            <Button sx={{background: 'red'}} onClick={this.signOut} type="submit" name="signin" id="signin" variant='login'>
                                LOG OUT
                            </Button>
                            <Button onClick={this.reRoute} type="submit" name="signin" id="signin" variant='login'>
                                GO TO APP
                            </Button>
                            <Button sx={{color: 'black', background: '#fad115'}} onClick={this.manage} type="submit" name="signin" id="signin" variant='login'>
                                MANAGE ACCOUNT
                            </Button>
                        </Box>
                        <br /><br />
                        {/* <Text textAlign='center' fontFamily='gilroyB'>
                            Have an Account? <Link style={{ textDecoration: 'none', color: '#f7ad1e', cursor: 'pointer' }} to="/sign-in">Sign in</Link>
                        </Text> */}
                        <br /><br />
                        <br /><br />
                    </Box>
                </Flex>
            </React.Fragment>
        );
    }
}

{/* <>
    <div className="main">
        <section className="signup">
            <div className="container">
                <div className="signup-content">
                    <div className="signup-form">
                        <h2 className="form-title">You're Signed in.</h2>
                        <p>Name: <b>{this.state.user.name}</b></p>
                        <p>Email: <b>{this.state.user.email}</b></p> <br />
                        <input onClick={this.signOut} type="button" name="signin" id="signin" className="form-submit" value="Sign Out" style={{ background: "red" }} />
                        <br />
                        <input onClick={this.reRoute} type="button" name="signin" id="signin" className="form-submit" value="Go to Application" />
                    </div>
                    <div className="signup-image">
                        <figure><img src={signUpImage} alt="" /></figure>
                    </div>
                </div>
            </div>
        </section>
    </div>
</> */}