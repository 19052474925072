/** jsx */
import { jsx, Label, Checkbox, Input } from 'theme-ui';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withAlert } from 'react-alert';
import validator from 'validator';
import bg from '../images/bg.webp';
import config from '../config';
import signUpImage from '../images/signup-image.jpg';
import { Flex, Box, Text, Button, Card } from 'rebass';
import Logo from './logo';

class Check_OTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: '',
            email: '',
            isValid: true,
            sending: false,
            verifying: false,
            disMsg: false,
            otpError: false
        }
    }

    componentDidMount = () => {
        if (this.props.location.state) {
            if (this.props.location.state.email) {
                this.setState({ email: this.props.location.state.email });
            }
        } else {
            this.props.history.push("/sign-in");
        }
    };

    componentWillUnmount = () => {
        if (this.state.disMsg) {
            this.props.alert.info("OTP is Verified");
        }
    }

    setOTP = (v) => {
        this.setState({ otp: v.target.value, otpError: false });
    }

    validateOTP = () => {
        if (!validator.isEmpty(this.state.otp)) {
            this.setState({ isValid: true, otpError: false });
        } else {
            this.setState({ isValid: false, otpError: true });
        }
    }

    sendOTP = () => {
        this.setState({ sending: true });
        const values = { email: this.state.email };
        fetch(`${config.api.url}/sso/send-otp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((response) => response.json()).then((data) => {
            this.setState({ sending: false });
            if (data.otp) {
                this.props.alert.info(data.message);
            } else {
                this.props.alert.error(data.message);
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    checkOTP = (e) => {
        e.preventDefault();
        if (this.state.isValid) {
            this.setState({ verifying: true });
            const values = { otp: this.state.otp, email: this.state.email };
            fetch(`${config.api.url}/sso/verify-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            }).then((response) => response.json()).then((data) => {
                if (data.user) {
                    if (this.props.location.state) {
                        if (this.props.location.state.forgot) {
                            this.setState({ verifying: false });
                            this.setState({ disMsg: true });
                            this.props.history.push('/reset-password', { reset: true, email: this.state.email });
                        } else {
                            this.props.history.push('/sign-in');
                        }
                    } else {
                        this.props.history.push('/sign-in');
                    }
                } else {
                    this.props.alert.error(data.message);
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
        } else {
            this.validateOTP();
        }
    }

    render = () => {
        return (
            <React.Fragment>
                <Flex height={'100vh'} sx={{ flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap', 'nowrap'] }}>
                    <Box minHeight={150} sx={{ background: `url(${bg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', overflow: 'hidden', position: 'static' }} width={[1, 1, 1, 1 / 2, 1 / 1.8, 1 / 1.6]}>
                    </Box>
                    <Box overflowY='auto' width={[1, 1, 1, 1 / 2, 1 / 2.3, 1 / 2.7]}>
                        <Box sx={{ mt: 40, mb: 0, mx: 'auto' }}>
                        <Flex justifyContent='center'>
                        <Logo sx={{ height: '30px', width: '250px'}}/>
                        </Flex>
                    </Box>
                        <Text variant='texts.welcome'>Check your Email for OTP</Text>
                        <form onSubmit={this.checkOTP}>
                        <Box sx={{ mt: 42, mx: 'auto' }} px={[20, 120, 120, 120, 120, 120]}>
                            <Card minHeight={30} variant='cards.login'>
                                    <Text fontSize='12px' fontFamily='gilroyB' color='#b6bbc1' sx={{ position: 'absolute', top: '15px', left: '20px' }}>OTP</Text>
                                    <Input onChange={(v) => { this.setOTP(v) }} type="password" name="otp" id="otp" />
                            </Card>
                            {!this.state.sending ? (
                                <p onClick={this.sendOTP} style={{ color: "orange", cursor: "pointer" }}> Resend OTP </p>) : (
                                    <p style={{ color: "orange" }}> Sending OTP </p>
                                )}
                            {this.state.verifying && (
                                <><br /><p style={{ color: "orange" }}> Verifying OTP </p></>
                            )}
                        </Box>
                        <Box px={[20, 120, 120, 120, 120, 120]}>
                            <Button onClick={this.checkOTP} type="submit" name="signin" id="signin" variant='login'>
                                SUBMIT OTP
                            </Button>
                        </Box>
                        </form>
                        <br /><br />
                        <Text textAlign='center' fontFamily='gilroyB'>
                            Have an Account? <Link style={{ textDecoration: 'none', color: '#f7ad1e', cursor: 'pointer' }} to="/sign-in">Sign in</Link>
                        </Text>
                    </Box>
                </Flex>
            </React.Fragment>
        );
    }
}

export default withAlert()(Check_OTP);

{/* <>
    <div className="main">
        <section className="signup">
            <div className="container">
                <div className="signup-content">
                    <div className="signup-form">
                        <h2 className="form-title">Check your Email for OTP</h2>
                        <form onSubmit={this.checkOTP} className="register-form" id="login-form">
                            <div className="form-group">
                                <span><i className="zmdi zmdi-lock material-icons-email" /> OTP</span>
                                <input onBlur={this.validateOTP} onChange={(v) => { this.setOTP(v) }} type="password" name="otp" id="otp" placeholder="The OTP" />
                                {this.state.otpError && (
                                    <span style={{ color: 'red' }}>Need a valid OTP</span>
                                )}
                            </div>
                            {!this.state.sending ? (
                                <span onClick={this.sendOTP} style={{ color: "blue", cursor: "pointer" }}> Resend OTP </span>) : (
                                    <span style={{ color: "orange" }}> Sending OTP </span>
                                )}
                            {this.state.verifying && (
                                <><br /><span style={{ color: "orange" }}> Verifying OTP </span></>
                            )}
                            <div className="form-group form-button">
                                <input type="submit" name="verify" id="verify" className="form-submit" value="Verify" />
                            </div>
                        </form>
                    </div>
                    <div className="signup-image">
                        <figure><img src={signUpImage} alt="" /></figure>
                    </div>
                </div>
            </div>
        </section>
    </div>
</> */}