const env = process.env.REACT_APP_ENV;

const config = {
    api: {
        url: ({
            dev: "https://sso.oscod.dev/api",
            pro: "https://sso.oscod.dev/api",
        })[env]
    },
    app: {
        url: ({
            dev: "http://localhost:3000",
            pro: "https://sso.oscod.dev",
        })[env]
    }
}

export default config;