/** jsx */
import { jsx, Label, Checkbox, Input } from 'theme-ui';
import React, { Component } from 'react';
import validator from 'validator';
import jwt_decode from 'jwt-decode';
import bg from '../images/bg.webp';
import config from '../config';
import { Flex, Box, Text, Button, Card } from 'rebass';
import Logo from './logo';
import { withAlert } from 'react-alert';

class Manage_Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            lastname: '',
            cp: false,
            disMsg: false,
            signing: false,
            isValid: true,
            nameError: false,
            emailError: false,
            passwordError: false,
            passwordMatchError: false,
            user: {
                name: "",
                lastname: '',
                email: "",
                id: '',
                token: null
            }
        }
    }

    componentWillUnmount = () => {
        if (this.state.disMsg) {
            this.props.alert.info("Password is Updated");
        }
    };

    componentDidMount() {
        if (localStorage.getItem('jwt') !== null) {
            if (!validator.isJWT(localStorage.getItem('jwt'))) {
                this.props.history.push('/sign-in');
            } else {
                var token = localStorage.getItem('jwt');
                if (token) {
                    if (validator.isJWT(token)) {
                        var user = jwt_decode(token);
                        const values = { email: user.email }; /* ${process.env.apiUrl} */
                        fetch(`${config.api.url}/sso/signed-in`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(values),
                        }).then((response) => response.json()).then((data) => {
                            if (data.user) {
                                this.setState({
                                    name: user.name,
                                    email: user.email,
                                    lastname: user.lastname,
                                    user: {
                                        id: user.id,
                                        name: user.name,
                                        email: user.email,
                                        lastname: user.lastname,
                                        token: localStorage.getItem('jwt')
                                    }
                                })
                                /* if (user.role === "manager") {
                                    this.setState({ isAdmin: true });
                                } */
                            } else {
                                localStorage.clear();
                                this.props.history.push('/sign-in');
                            }
                        }).catch((error) => {
                            console.error('Error:', error);
                        });
                    }
                }
            }
            if (localStorage.getItem('url') !== null) {
                this.setState({ referrer: localStorage.getItem('url') })
            } else {
                this.setState({ referrer: `${config.api.url}/sign-in` });
            }
        } else {
            this.props.history.push('/sign-in');
        }
    }

    reRoute = () => {
        window.location.assign(this.state.referrer + "?jwt=" + this.state.user.token);
    }

    setName = (v) => {
        this.setState({ name: v.target.value, nameError: false });
    }

    setLastName = (v) => {
        this.setState({ lastname: v.target.value, nameError: false });
    }

    setEmail = (v) => {
        this.setState({ email: v.target.value, emailError: false });
    }

    setPassword = (v) => {
        this.setState({ password: v.target.value, passwordError: false });
    }

    validateName = () => {
        if (!validator.isEmpty(this.state.name)) {
            this.setState({ isValid: true, nameError: false });
        } else {
            this.setState({ isValid: false, nameError: true });
        }
    }

    validateEmail = () => {
        if (validator.isEmail(this.state.email)) {
            this.setState({ isValid: true, emailError: false });
        } else {
            this.setState({ isValid: false, emailError: true });
        }
    }

    validatePassword = () => {
        if (!validator.isEmpty(this.state.password)) {
            this.setState({ isValid: true, passwordError: false });
        } else {
            this.setState({ isValid: false, passwordError: true });
        }
    }

    matchPassword = (v) => {
        if (validator.equals(v.target.value, this.state.password)) {
            this.setState({ isValid: true, matchPasswordError: false });
        } else {
            this.setState({ isValid: false, matchPasswordError: true });
        }
    }

    updateProfile = (e) => {
        e.preventDefault();
        if (this.state.isValid) {
            this.setState({ signing: true });
            const values = { uid: this.state.user.id, name: this.state.name, lastname: this.state.lastname, email: this.state.email, sso: true };
            fetch(`${config.api.url}/sso/put`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            }).then((response) => response.json()).then((data) => {
                this.setState({ signing: false });
                if (data.suc) {
                    localStorage.clear();
                    /*this.props.history.push('/sign-in'); */
                    const values = { email: this.state.email };
                    fetch(`${config.api.url}/sso/sign-out`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(values),
                    }).then((response) => response.json()).then((data) => {
                        if (data.user) {
                            localStorage.clear();
                            this.props.alert.success('Profile Updated, Please Login Again.');
                            this.props.history.push('/sign-in');
                        } else {
                            this.props.alert.error(data.message);
                        }
                    }).catch((error) => {
                        console.error('Error:', error);
                    });
                } else {
                    this.props.alert.error(data.message);
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
        } else {
            this.validateName();
            this.validateEmail();
        }
    }

    resetPassword = (e) => {
        e.preventDefault();
        if (this.state.isValid) {
            this.setState({ reseting: true });
            const values = { email: this.state.email, password: this.state.password };
            fetch(`${config.api.url}/sso/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            }).then((response) => response.json()).then((data) => {
                this.setState({ reseting: false });
                if (data.user) {
                    //this.setState({disMsg: true});
                    //this.props.history.push('/sign-in');
                    fetch(`${config.api.url}/sso/sign-out`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(values),
                    }).then((response) => response.json()).then((data) => {
                        if (data.user) {
                            this.setState({disMsg: true});
                            this.props.history.push('/sign-in');
                        } else {
                            this.props.alert.error(data.message);
                        }
                    }).catch((error) => {
                        console.error('Error:', error);
                    });
                } else {
                    this.props.alert.error(data.message);
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
        }
    }

    render = () => {
        return (
            <React.Fragment>
                <Flex>
                    <Box sx={{ mx: 'auto' }} width={[1, 1, 1, 1 / 2, 1 / 2.3, 1 / 2.7]}>
                        <Box sx={{ mt: 90, mb: 20, mx: 'auto' }}>
                            <Flex justifyContent='center'>
                                <Logo style={{ height: '30px', width: '250px' }} />
                            </Flex>
                        </Box>
                        <Text variant='texts.welcome'></Text>
                        {!this.state.cp && (<><Box sx={{ mt: 42, mx: 'auto', textAlign: 'center' }} px={[20, 120, 120, 120, 120, 120]}>
                            <Card>
                                <br />
                                <Text fontSize={18}>Manage your Account</Text>
                                <br />
                                <Card variant='cards.login'>
                                    <Text fontSize='12px' fontFamily='gilroyB' color='#b6bbc1' sx={{ position: 'absolute', top: '15px', left: '20px' }}>First Name</Text>
                                    <Input defaultValue={this.state.user.name} onChange={(v) => { this.setName(v) }} type="text" name="name" id="name" />
                                    <Text fontSize='12px' fontFamily='gilroyB' color='#b6bbc1' sx={{ position: 'absolute', top: '90px', left: '20px' }}>Last Name</Text>
                                    <Input defaultValue={this.state.user.lastname} onChange={(v) => { this.setLastName(v) }} type="text" name="lname" id="lname" />
                                    <Text fontSize='12px' fontFamily='gilroyB' color='#b6bbc1' sx={{ position: 'absolute', top: '160px', left: '20px' }}>Email Address</Text>
                                    <Input defaultValue={this.state.user.email} onChange={(v) => { this.setEmail(v) }} type="text" name="email" id="email" />
                                </Card>
                            </Card>
                        </Box>
                        <br /><br />
                        <Box px={[20, 120, 120, 120, 120, 120]}>
                            <Button onClick={this.updateProfile} type="submit" name="signin" id="signin" variant='login'>
                                UPDATE
                            </Button>
                            <Button sx={{ color: 'black', background: '#fad115' }} onClick={()=>{this.setState({cp: true})}} type="submit" name="signin" id="signin" variant='login'>
                                CHANGE PASSWORD
                            </Button>
                        </Box></>)}{/* ) : ( */}
                            {this.state.cp && (<><Box sx={{ mt: 42, mx: 'auto', textAlign: 'center' }} px={[20, 120, 120, 120, 120, 120]}>
                            <Card>
                                <br />
                                <Text fontSize={18}>Manage your Password</Text>
                                <br />
                                <Card variant='cards.login'>
                                <Text fontSize='12px' fontFamily='gilroyB' color='#b6bbc1' sx={{ position: 'absolute', bottom: '120px', left: '20px' }}>New Password</Text>
                                    <Input defaultValue={''} onChange={(v) => { this.setPassword(v) }} type="password" name="password" id="password" />
                                    <Text fontSize='12px' fontFamily='gilroyB' color='#b6bbc1' sx={{ position: 'absolute', bottom: '40px', left: '20px' }}>Confirm New Password</Text>
                                    <Input defaultValue={''} onChange={(v) => { this.matchPassword(v) }} type="password" name="confirm_password" id="confirm_password" />
                                </Card>
                                {this.state.passwordError && (
                                    <span style={{ color: 'red' }}>Password is Required</span>
                                )}
                                {this.state.matchPasswordError && (
                                    <span style={{ color: 'red' }}>Passwords Don't Match</span>
                                )}
                                {this.state.reseting && (
                                <span style={{ color: "orange" }}> Reseting your Password </span>
                            )}
                            </Card>
                        </Box>
                        <br /><br />
                        <Box px={[20, 120, 120, 120, 120, 120]}>
                            <Button onClick={this.resetPassword} type="submit" name="signin" id="signin" variant='login'>
                                RESET
                            </Button>
                            <Button sx={{ color: 'black', background: '#fad115' }} onClick={()=>{this.setState({cp: false})}} type="submit" name="signin" id="signin" variant='login'>
                                MANAGE ACCOUNT
                            </Button>
                        </Box></>)}
                        {/* )} */}
                        <br />
                        {/* <Text textAlign='center' fontFamily='gilroyB'>
                            Have an Account? <Link style={{ textDecoration: 'none', color: '#f7ad1e', cursor: 'pointer' }} to="/sign-in">Sign in</Link>
                        </Text> */}
                        <br /><br />
                        <br /><br />
                    </Box>
                </Flex>
            </React.Fragment>
        );
    }
}

export default withAlert()(Manage_Account);

{/* <>
    <div className="main">
        <section className="signup">
            <div className="container">
                <div className="signup-content">
                    <div className="signup-form">
                        <h2 className="form-title">You're Signed in.</h2>
                        <p>Name: <b>{this.state.user.name}</b></p>
                        <p>Email: <b>{this.state.user.email}</b></p> <br />
                        <input onClick={this.signOut} type="button" name="signin" id="signin" className="form-submit" value="Sign Out" style={{ background: "red" }} />
                        <br />
                        <input onClick={this.reRoute} type="button" name="signin" id="signin" className="form-submit" value="Go to Application" />
                    </div>
                    <div className="signup-image">
                        <figure><img src={signUpImage} alt="" /></figure>
                    </div>
                </div>
            </div>
        </section>
    </div>
</> */}