/** @jsx jsx */
import { jsx, Label, Checkbox, Input } from 'theme-ui';

import React, { Component } from 'react';
import { withAlert } from 'react-alert';
import validator from 'validator';
import { Link } from 'react-router-dom';
import bg from '../images/bg.webp';
import config from '../config';
import signUpImage from '../images/signup-image.jpg';
import { Flex, Box, Text, Button, Card } from 'rebass';
import Logo from './logo';

class Reset_Password extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            disMsg: false,
            isValid: true,
            reseting: false,
            passwordError: false,
            matchPasswordError: false,
        }
    }

    componentDidMount = () => {
        if (this.props.location.state) {
            if (this.props.location.state.reset) {
                this.setState({ email: this.props.location.state.email });
            }
        } else {
            this.props.history.push("/sign-in");
        }
    };

    componentWillUnmount = () => {
        if (this.state.disMsg) {
            this.props.alert.info("Password is Updated");
        }
    };

    setPassword = (v) => {
        this.setState({ password: v.target.value, passwordError: false });
    }

    validatePassword = () => {
        if (!validator.isEmpty(this.state.password)) {
            this.setState({ isValid: true, passwordError: false });
        } else {
            this.setState({ isValid: false, passwordError: true });
        }
    }

    matchPassword = (v) => {
        if (validator.equals(v.target.value, this.state.password)) {
            this.setState({ isValid: true, matchPasswordError: false });
        } else {
            this.setState({ isValid: false, matchPasswordError: true });
        }
    }

    resetPassword = (e) => {
        e.preventDefault();
        if (this.state.isValid) {
            this.setState({ reseting: true });
            const values = { email: this.state.email, password: this.state.password };
            fetch(`${config.api.url}/sso/reset-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            }).then((response) => response.json()).then((data) => {
                this.setState({ reseting: false });
                if (data.user) {
                    this.setState({disMsg: true});
                    this.props.history.push('/sign-in');
                } else {
                    this.props.alert.error(data.message);
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
        }
    }

    render = () => {
        return (
            <React.Fragment>
                <Flex height={'100vh'} sx={{ flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap', 'nowrap'] }}>
                    <Box minHeight={150} sx={{ background: `url(${bg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', overflow: 'hidden', position: 'static' }} width={[1, 1, 1, 1 / 2, 1 / 1.8, 1 / 1.6]}>
                    </Box>
                    <Box overflowY='auto' width={[1, 1, 1, 1 / 2, 1 / 2.3, 1 / 2.7]}>
                        <Box sx={{ mt: 40, mb: 0, mx: 'auto' }}>
                        <Flex justifyContent='center'>
                        <Logo sx={{ height: '30px', width: '250px'}}/>
                        </Flex>
                    </Box>
                        <Text variant='texts.welcome'>Reset your Password</Text>
                        <form onSubmit={this.resetPassword}>
                        <Box sx={{ mt: 40, mx: 'auto' }} px={[20, 120, 120, 120, 120, 120]}>
                            <Card variant='cards.login'>
                                    <Text fontSize='12px' fontFamily='gilroyB' color='#b6bbc1' sx={{ position: 'absolute', bottom: '120px', left: '20px' }}>New Password</Text>
                                    <Input onChange={(v) => { this.setPassword(v) }} type="password" name="password" id="password" />
                                    <Text fontSize='12px' fontFamily='gilroyB' color='#b6bbc1' sx={{ position: 'absolute', bottom: '40px', left: '20px' }}>Confirm New Password</Text>
                                    <Input onChange={(v) => { this.matchPassword(v) }} type="password" name="confirm_password" id="confirm_password" />
                            </Card>
                            {this.state.passwordError && (
                                    <span style={{ color: 'red' }}>Password is Required</span>
                                )}
                                {this.state.matchPasswordError && (
                                    <span style={{ color: 'red' }}>Passwords Don't Match</span>
                                )}
                                {this.state.reseting && (
                                <span style={{ color: "orange" }}> Reseting your Password </span>
                            )}
                        </Box>
                        <Box px={[20, 120, 120, 120, 120, 120]}>
                            <Button onClick={this.resetPassword} type="submit" name="signin" id="signin" variant='login'>
                                RESET PASSWORD
                            </Button>
                        </Box>
                        </form>
                        <br /><br />
                        <Text textAlign='center' fontFamily='gilroyB'>
                            Have an Account? <Link style={{ textDecoration: 'none', color: '#f7ad1e', cursor: 'pointer' }} to="/sign-in">Login Now</Link>
                        </Text>
                    </Box>
                </Flex>
            </React.Fragment>
        );
    }
}

export default withAlert()(Reset_Password);

{/* <>
    <div className="main">
        <section className="signup">
            <div className="container">
                <div className="signup-content">
                    <div className="signup-form">
                        <h2 className="form-title">Reset your Password</h2>
                        <form onSubmit={this.resetPassword} className="register-form" id="login-form">
                            <div className="form-group">
                                <span><i className="zmdi zmdi-lock" /> Password</span>
                                <input onBlur={this.validatePassword} onChange={(v) => { this.setPassword(v) }} type="password" name="pass" id="pass" placeholder="New Password" />
                                {this.state.passwordError && (
                                    <span style={{ color: 'red' }}>Password is Required</span>
                                )}
                            </div>
                            <div className="form-group">
                                <span><i className="zmdi zmdi-lock-outline" /> Confirm</span>
                                <input onChange={(v) => { this.matchPassword(v) }} type="password" name="confirm_password" id="confirm_password" placeholder="Confirm Password" />
                                {this.state.matchPasswordError && (
                                    <span style={{ color: 'red' }}>Passwords Don't Match</span>
                                )}
                            </div>
                            {this.state.reseting && (
                                <span style={{ color: "orange" }}> Reseting your Password </span>
                            )}
                            <div className="form-group form-button">
                                <input type="submit" name="verify" id="verify" className="form-submit" value="Reset Password" />
                            </div>
                        </form>
                    </div>
                    <div className="signup-image">
                        <figure><img src={signUpImage} alt="" /></figure>
                    </div>
                </div>
            </div>
        </section>
    </div>
</> */}