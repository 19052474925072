/** @jsx jsx */
import { jsx, Label, Checkbox, Input } from 'theme-ui';

import React, { Component } from 'react';
import { withAlert } from 'react-alert';
import validator from 'validator';
import { Link } from 'react-router-dom';
import bg from '../images/bg.webp';
import config from '../config';
import signUpImage from '../images/signup-image.jpg';
import { Flex, Box, Text, Button, Card } from 'rebass';
import Logo from './logo';

class Forgot_Password extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            isValid: true,
            searching: false,
            emailError: false
        }
    }

    setEmail = (v) => {
        this.setState({ email: v.target.value, emailError: false });
    }

    validateEmail = () => {
        if (validator.isEmail(this.state.email)) {
            this.setState({ isValid: true, emailError: false });
        } else {
            this.setState({ isValid: false, emailError: true });
        }
    }

    sendOTP = (e) => {
        e.preventDefault();
        if (this.state.isValid) {
            this.setState({ searching: true });
            const values = { email: this.state.email };
            fetch(`${config.api.url}/sso/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            }).then((response) => response.json()).then((data) => {
                if (data.otp) {
                    this.setState({ sending: false })
                    this.props.history.push('/check-otp', { forgot: true, email: this.state.email })
                } else {
                    this.setState({ searching: false })
                    this.props.alert.error(data.message);
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
        } else {
            this.validateEmail();
        }
    }

    render = () => {
        return (
            <React.Fragment>
                <Flex height={'100vh'} sx={{ flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap', 'nowrap'] }}>
                    <Box minHeight={150} sx={{ background: `url(${bg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', overflow: 'hidden', position: 'static' }} width={[1, 1, 1, 1 / 2, 1 / 1.8, 1 / 1.6]}>
                    </Box>
                    <Box overflowY='auto' width={[1, 1, 1, 1 / 2, 1 / 2.3, 1 / 2.7]}>
                        <Box sx={{ mt: 40, mb: 0, mx: 'auto' }}>
                        <Flex justifyContent='center'>
                        <Logo sx={{ height: '30px', width: '250px'}}/>
                        </Flex>
                    </Box>
                        <Text variant='texts.welcome'>Reset your Account.</Text>
                        <form onSubmit={this.sendOTP}>
                        <Box sx={{ mt: 40, mx: 'auto' }} px={[20, 120, 120, 120, 120, 120]}>
                            <Card minHeight={30} variant='cards.login'>
                                    <Text fontSize='12px' fontFamily='gilroyB' color='#b6bbc1' sx={{ position: 'absolute', top: '15px', left: '20px' }}>Email Address</Text>
                                    <Input onChange={(v) => { this.setEmail(v) }} type="text" name="email" id="email" />
                            </Card>
                            {this.state.emailError && (
                                <p style={{ color: 'red' }}>Need a valid Email Address</p>
                            )}
                        </Box>
                        <Box px={[20, 120, 120, 120, 120, 120]}>
                            <Button onClick={this.sendOTP} type="submit" name="signin" id="signin" variant='login'>
                                REQUEST OTP
                            </Button>
                        </Box>
                        </form>
                        <br /><br />
                        <Text textAlign='center' fontFamily='gilroyB'>
                            Have an Account? <Link style={{ textDecoration: 'none', color: '#f7ad1e', cursor: 'pointer' }} to="/sign-in">Sign in</Link>
                        </Text>
                    </Box>
                </Flex>
            </React.Fragment>
        );
    }
}

export default withAlert()(Forgot_Password);

{/* <>
<div className="main">
    <section className="signup">
        <div className="container">
            <div className="signup-content">
                <div className="signup-form">
                    <h2 className="form-title">Enter your Email</h2>
                    <form onSubmit={this.sendOTP} className="register-form" id="login-form">
                        <div className="form-group">
                            <span><i className="zmdi zmdi-email material-icons-email" /> Email</span>
                            <input onBlur={this.validateEmail} onChange={(v) => { this.setEmail(v) }} type="text" name="email" id="email" placeholder="Your Email" />
                            {this.state.emailError && (
                                <span style={{ color: 'red' }}>Need a valid Email Address</span>
                            )}
                        </div>
                        {this.state.searching && (
                            <span style={{ color: "orange" }}> Searching for User </span>
                        )}
                        <div className="form-group form-button">
                            <input type="submit" name="verify" id="verify" className="form-submit" value="Request OTP" />
                        </div>
                    </form>
                </div>
                <div className="signup-image">
                    <figure><img src={signUpImage} alt="" /></figure>
                </div>
            </div>
        </div>
    </section>
</div>
</> */}