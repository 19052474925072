/** @jsx jsx */
import { jsx, Label, Checkbox, Input, useThemeUI } from 'theme-ui';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import validator from 'validator';
import { withAlert } from 'react-alert';
import scriptjs from 'scriptjs';
import Logo from './logo';
import config from '../config';
import signUpImage from '../images/signup-image.jpg';
import bg from '../images/bg.webp';
import apple from '../images/apple.png';
import { Flex, Box, Text, Button, Card } from 'rebass';

//import AwesomeButtonProgress from 'react-awesome-button/src/components/AwesomeButtonProgress';

class Sign_In extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isValid: true,
            signing: false,
            emailError: false,
            passwordError: false
        }
    }

    componentDidMount = () => {
        if (this.props.match.params.data) {
            if (validator.isJWT(this.props.match.params.data)) {
                localStorage.setItem('jwt', this.props.match.params.data);
                if (localStorage.getItem('url')) {
                    window.location.assign(localStorage.getItem('url') + "?jwt=" + this.props.match.params.data);
                }
            }
        } else {
            this.setState({ referrer: document.referrer }, () => {
                localStorage.setItem('url', this.state.referrer);
                if (localStorage.getItem('jwt') !== null) {
                    if (validator.isJWT(localStorage.getItem('jwt'))) {
                        this.props.history.push('/signed-in');
                    }
                }
            });
        }
        scriptjs.get('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', () => {
            const params = {
                clientId: 'sso.oscod.dev',
                redirectURI: 'https://sso.oscod.dev/api/sso/apple/callback',
                scope: 'name email',
            };
            window.AppleID.auth.init(params);
        });
    }

    setEmail = (v) => {
        this.setState({ email: v.target.value, emailError: false });
    }

    setPassword = (v) => {
        this.setState({ password: v.target.value, passwordError: false });
    }

    validateEmail = () => {
        if (validator.isEmail(this.state.email)) {
            this.setState({ isValid: true, emailError: false });
        } else {
            this.setState({ isValid: false, emailError: true });
        }
    }

    validatePassword = () => {
        if (!validator.isEmpty(this.state.password)) {
            this.setState({ isValid: true, passwordError: false });
        } else {
            this.setState({ isValid: false, passwordError: true });
        }
    }

    signIn = (e) => {
        e.preventDefault();
        if (this.state.isValid) {
            this.setState({ signing: true });
            const values = { email: this.state.email, password: this.state.password };
            fetch(`${config.api.url}/sso/sign-in`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            }).then((response) => response.json()).then((data) => {
                this.setState({ signing: false });
                if (data.user) {
                    if (data.verified === 1) {
                        localStorage.setItem('jwt', data.token);
                        window.location.assign(this.state.referrer + "?&jwt=" + data.token);
                    } else {
                        this.props.history.push('/check-otp', { email: this.state.email });
                    }
                } else {
                    this.props.alert.error('Invalid Email / Password');
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
        } else {
            this.validateEmail();
            this.validatePassword();
        }
    }

    render = () => {
        return (
            <React.Fragment>
                <Flex height={'100vh'} sx={{ flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap', 'nowrap'] }}>
                    <Box minHeight={150} sx={{ background: `url(${bg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', overflow: 'hidden', position: 'static' }} width={[1, 1, 1, 1 / 2, 1 / 1.8, 1 / 1.6]}>
                    </Box>
                    <Box overflowY='auto' width={[1, 1, 1, 1 / 2, 1 / 2.3, 1 / 2.7]}>
                        <Box sx={{ mt: 40, mb: 0, mx: 'auto' }}>
                            <Flex justifyContent='center'>
                                <Logo sx={{ height: '30px', width: '250px' }} />
                            </Flex>
                        </Box>
                        <Text variant='texts.welcome'>Welcome Back, <br /> Please Login to your Account.</Text>
                        <Box sx={{ mt: 40, mx: 'auto' }}>
                            <Flex justifyContent='center' flexWrap='wrap' px={[20, 120, 120, 120, 120, 120]}>
                                <Button backgroundColor={'#4267b2'} variant='social'>
                                    <Flex alignItems={'center'} justifyContent='center'>
                                    <svg fill='white' sx={{ height: '15px', width: '15px', mr: 10}} id="Bold" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m15.997 3.985h2.191v-3.816c-.378-.052-1.678-.169-3.192-.169-3.159 0-5.323 1.987-5.323 5.639v3.361h-3.486v4.266h3.486v10.734h4.274v-10.733h3.345l.531-4.266h-3.877v-2.939c.001-1.233.333-2.077 2.051-2.077z" /></svg>
                                    Login with Facebook
                                    </Flex>
                                </Button>
                                <Button backgroundColor={'#1da1f2'} variant='social'>
                                <Flex alignItems={'center'} justifyContent='center'>
                                    <svg fill='white' sx={{ height: '15px', width: '15px', mr: 20}} version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" > <g> <g> <path d="M512,97.248c-19.04,8.352-39.328,13.888-60.48,16.576c21.76-12.992,38.368-33.408,46.176-58.016c-20.288,12.096-42.688,20.64-66.56,25.408C411.872,60.704,384.416,48,354.464,48c-58.112,0-104.896,47.168-104.896,104.992c0,8.32,0.704,16.32,2.432,23.936c-87.264-4.256-164.48-46.08-216.352-109.792c-9.056,15.712-14.368,33.696-14.368,53.056c0,36.352,18.72,68.576,46.624,87.232c-16.864-0.32-33.408-5.216-47.424-12.928c0,0.32,0,0.736,0,1.152c0,51.008,36.384,93.376,84.096,103.136c-8.544,2.336-17.856,3.456-27.52,3.456c-6.72,0-13.504-0.384-19.872-1.792c13.6,41.568,52.192,72.128,98.08,73.12c-35.712,27.936-81.056,44.768-130.144,44.768c-8.608,0-16.864-0.384-25.12-1.44C46.496,446.88,101.6,464,161.024,464c193.152,0,298.752-160,298.752-298.688c0-4.64-0.16-9.12-0.384-13.568C480.224,136.96,497.728,118.496,512,97.248z" /> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>
                                    Login with Twitter
                                    </Flex>
                                </Button>
                                <Button onClick={() => { window.location.assign(`https://sso.oscod.dev/api/sso/google/?ref=${this.state.referrer}`); }} backgroundColor={'#f43d3d'} variant='social'>
                                <Flex alignItems={'center'} justifyContent='center'>
                                    <svg sx={{ height: '15px', width: '15px', mr: 20}} id="Capa_1" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g><path d="m120 256c0-25.367 6.989-49.13 19.131-69.477v-86.308h-86.308c-34.255 44.488-52.823 98.707-52.823 155.785s18.568 111.297 52.823 155.785h86.308v-86.308c-12.142-20.347-19.131-44.11-19.131-69.477z" fill="white" /><path d="m256 392-60 60 60 60c57.079 0 111.297-18.568 155.785-52.823v-86.216h-86.216c-20.525 12.186-44.388 19.039-69.569 19.039z" fill="white" /><path d="m139.131 325.477-86.308 86.308c6.782 8.808 14.167 17.243 22.158 25.235 48.352 48.351 112.639 74.98 181.019 74.98v-120c-49.624 0-93.117-26.72-116.869-66.523z" fill="white" /><path d="m512 256c0-15.575-1.41-31.179-4.192-46.377l-2.251-12.299h-249.557v120h121.452c-11.794 23.461-29.928 42.602-51.884 55.638l86.216 86.216c8.808-6.782 17.243-14.167 25.235-22.158 48.352-48.353 74.981-112.64 74.981-181.02z" fill="white" /><path d="m352.167 159.833 10.606 10.606 84.853-84.852-10.606-10.606c-48.352-48.352-112.639-74.981-181.02-74.981l-60 60 60 60c36.326 0 70.479 14.146 96.167 39.833z" fill="white" /><path d="m256 120v-120c-68.38 0-132.667 26.629-181.02 74.98-7.991 7.991-15.376 16.426-22.158 25.235l86.308 86.308c23.753-39.803 67.246-66.523 116.87-66.523z" fill="white" /></g></svg>
                                    Login with Google
                                    </Flex>
                                </Button>
                                <Button onClick={() => { window.AppleID.auth.signIn() }} backgroundColor={'#22252a'} variant='social'>
                                <Flex alignItems={'center'} justifyContent='center'>
                                    <svg fill='white' sx={{ height: '15px', width: '15px', mr: 25}} version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
                                        <g>
                                            <path d="M185.255,512c-76.201-0.439-139.233-155.991-139.233-235.21c0-129.404,97.075-157.734,134.487-157.734   c16.86,0,34.863,6.621,50.742,12.48c11.104,4.087,22.588,8.306,28.975,8.306c3.823,0,12.832-3.589,20.786-6.738   c16.963-6.753,38.071-15.146,62.651-15.146c0.044,0,0.103,0,0.146,0c18.354,0,74.004,4.028,107.461,54.272l7.837,11.777   l-11.279,8.511c-16.113,12.158-45.513,34.336-45.513,78.267c0,52.031,33.296,72.041,49.292,81.665   c7.061,4.248,14.37,8.628,14.37,18.208c0,6.255-49.922,140.566-122.417,140.566c-17.739,0-30.278-5.332-41.338-10.034   c-11.191-4.761-20.845-8.862-36.797-8.862c-8.086,0-18.311,3.823-29.136,7.881C221.496,505.73,204.752,512,185.753,512H185.255z" />
                                            <path d="M351.343,0c1.888,68.076-46.797,115.304-95.425,112.342C247.905,58.015,304.54,0,351.343,0z" />
                                        </g>
                                    </svg>
                                    Login with Apple
                                    </Flex>
                                </Button>
                            </Flex>
                        </Box>
                        <Text textAlign='center' fontFamily='gilroyB'>OR</Text>
                        <br />
                        <form onSubmit={this.signIn}>
                            <Box px={[20, 120, 120, 120, 120, 120]}>
                                <Card variant='cards.login'>
                                    <Text fontSize='12px' fontFamily='gilroyB' color='#b6bbc1' sx={{ position: 'absolute', top: '15px', left: '20px' }}>Email Address</Text>
                                    <Input onChange={(v) => { this.setEmail(v) }} type="text" name="email" id="email" />
                                    <Text fontSize='12px' fontFamily='gilroyB' color='#b6bbc1' sx={{ position: 'absolute', bottom: '40px', left: '20px' }}>Password</Text>
                                    <Input onChange={(v) => { this.setPassword(v) }} type="password" name="password" id="password" />
                                </Card>
                                {this.state.emailError && (
                                    <p style={{ color: 'red' }}>Need a valid Email Address</p>
                                )}
                                {this.state.passwordError && (
                                    <p style={{ color: 'red' }}>Password is Required</p>
                                )}
                                {this.state.signing && (
                                    <p style={{ color: "orange" }}> Signing in </p>
                                )}
                            </Box>
                            <Box px={[20, 120, 120, 120, 120, 120]}>
                                <Button onClick={this.signIn} type="submit" name="signin" id="signin" variant='login'>
                                    LOGIN
                            </Button>
                            </Box> 
                            <br />
                            <Box px={[20, 120, 120, 120, 120, 120]}>
                                <Flex justifyContent='space-between'>
                                    <Box sx={{ position: 'relative' }} width={1 / 2}>
                                        <Text textAlign='left' fontFamily='gilroyB'>
                                            {/* Don’t have an Account?  */}<Link style={{ textDecoration: 'none', color: '#f7ad1e', cursor: 'pointer' }} to="/sign-up">Register Now</Link>
                                        </Text>
                                    </Box>
                                    <Box width={1 / 2}>
                                        <Text onClick={() => { this.props.history.push("/forgot-password") }} sx={{ fontFamily: 'gilroyB', textAlign: 'right', color: '#f7ad1e', cursor: 'pointer' }}>
                                            Forgot Password?
                                </Text>
                                    </Box>
                                </Flex>
                            </Box>
                        </form>
                        <br /><br />
                    </Box>
                </Flex>
            </React.Fragment>
        );
    }
}

export default withAlert()(Sign_In);

{/* <div className="main">
    <section className="signup">
        <div className="container">
            <div className="signup-content">
                <div className="signup-form">
                    <h2 className="form-title">Sign in</h2>
                    <form onSubmit={this.signIn} className="register-form" id="login-form">
                        <div className="form-group">
                            <span><i className="zmdi zmdi-email material-icons-email" /> Email</span>
                            <input onBlur={this.validateEmail} onChange={(v) => { this.setEmail(v) }} type="text" name="email" id="email" placeholder="Your Email" />
                            {this.state.emailError && (
                                <span style={{ color: 'red' }}>Need a valid Email Address</span>
                            )}
                        </div>
                        <div className="form-group">
                            <span><i className="zmdi zmdi-lock" /> Password</span>
                            <input onBlur={this.validatePassword} onChange={(v) => { this.setPassword(v) }} type="password" name="password" id="password" placeholder="Your Password" />
                            {this.state.passwordError && (
                                <span style={{ color: 'red' }}>Password is Required</span>
                            )}
                        </div>
                        {this.state.signing && (
                            <><span style={{ color: "orange" }}> Signing in </span> <br /></>
                        )}
                        <span style={{ color: "black", marginRight: '10px' }}>Sign in with: </span> <img src={g} style={{ cursor: 'pointer' }} width='18px' onClick={() => { window.location.assign(`https://sso.oscod.dev/api/sso/google/?ref=${this.state.referrer}`); }} /> <img src={apple} style={{ cursor: 'pointer', marginRight: '10px' }} width='18px' onClick={() => { window.AppleID.auth.signIn() }} /> <br /> <br />
                        <span onClick={() => { this.props.history.push("/forgot-password") }} style={{ color: "blue", cursor: "pointer" }}>Forgot Password</span>
                        <div className="form-group form-button">
                            <input type="submit" name="signin" id="signin" className="form-submit" value="Sign in" />
                        </div>
                    </form>
                </div>
                <div className="signup-image">
                    <figure><img src={signUpImage} alt="" /></figure>
                    <Link style={{ textDecoration: 'none' }} to="/sign-up"><p style={{ cursor: 'pointer', color: 'blue' }} className="signup-image-link">Create an Account</p></Link>
                </div>
            </div>
        </div>
    </section>
</div> */}