import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ThemeProvider, useColorMode } from 'theme-ui';

import './app.css';
import theme from './theme';
import './fonts/material-icon/css/material-design-iconic-font.min.css';

import Sign_Up from "./components/sign-up";
import Check_OTP from "./components/check-otp";
import Sign_In from "./components/sign-in";
import Forgot_Password from "./components/forgot-password";
import Reset_Password from "./components/reset-password";
import Signed_In from "./components/signed-in";
import Manage_Account from "./components/manage-account";

class App extends Component {
    render = () => {
        return (
            <ThemeProvider theme={theme}>
            <Route>
                <Switch>
                    <Route exact path="/sign-up" component={Sign_Up} />
                    <Route exact path="/check-otp" component={Check_OTP} />
                    <Route exact path="/sign-in" component={Sign_In} />
                    <Route exact path="/sign-in/:data" component={Sign_In} />
                    <Route exact path="/forgot-password" component={Forgot_Password} />
                    <Route exact path="/reset-password" component={Reset_Password} />
                    <Route exact path="/signed-in" component={Signed_In} />
                    <Route exact path="/manage-account" component={Manage_Account} />
                    <Redirect from="*" to="/sign-in" />
                </Switch>
            </Route>
            </ThemeProvider>
        );
    }
}

export default App;
