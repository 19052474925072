const theme = {
 breakpoints: ['449px', '769px', '1025px', '1441px', '1601px', '1921px', '2561px'],
 colors: {
  text: '#000000',
  background: '#ffffff',
  welcome: '#1d202a',
  cardborder: '#f1f3f8',
  loginbg: '#22252a',
  modes: {
   dark: {
    text: '#ffffff',
    background: '#000000',
    welcome: '#fefeff',
    cardborder: '#000',
    loginbg: '#ffffff',
   },
   light: {
    text: '#000000',
    background: '#ffffff',
    welcome: '#1d202a',
    cardborder: '#f1f3f8',
    loginbg: '#22252a',
   }
  }
 },
 forms: {
  input: {
   mt: '35px',
   border: 0,
   px: '20px',
   borderRadius: 0,
   borderBottom: 'solid 2px #f1f3f8',
  }
 },
 cards: {
  login: {
   width: '100%',
   minHeight: '150px',
   position: 'relative',
   border: 'solid 2px',
   borderColor: 'cardborder',
   boxShadow: '0px 45px 73.1px 13.9px rgba(0, 0, 0, 0.06)'
  }
 },
 texts: {
  welcome: {
   color: 'welcome',
   fontFamily: 'gilroyB',
   textAlign: 'center',
   mt: ['30px','30px','30px','30px','30px','30px'],
   fontSize: '16px'
  }
 },
 buttons: {
  social: {
   width: '100%',
   mb: '12px',
   height: '50px',
   borderRadius: '5px',
   cursor: 'pointer',
   '&:focus': {
    outline: '0'
   }
  },
  login: {
   backgroundColor: 'loginbg',
   width: '100%',
   height: '55px',
   fontFamily: 'gilroyB',
   color: 'background',
   borderRadius: '5px',
   fontSize: '15px',
   cursor: 'pointer',
   '&:focus': {
    outline: '0'
   }
  }
 },
 fonts: {
  gilroyL: 'GilroyL, sans-serif',
  gilroyB: 'GilroyB, sans-serif'
 },
 styles: {
  root: {
   fontFamily: 'gilroyL'
  }
 },
}

export default theme;
